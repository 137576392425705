<template>
  <div class="user-box">
    <!-- 搜索 -->
    <search-form @search="search"></search-form>

    <!-- 链接表 -->
    <statistics-table :statistics-list="statisticsList"></statistics-table>

    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>

<script>
// components
import searchForm from './components/search-form';
import statisticsTable from './components/statistics-table';
// api
import service from './api';
import pagination from '@/mixins/pagination';

export default {
  mixins: [pagination],
  data() {
    return {
      searchData: {},
      statisticsList: [],
      page: {}
    };
  },
  components: {
    searchForm,
    statisticsTable
  },
  methods: {
    init() {
      let initParam = { pageNum: 1 };
      this.getList(initParam);
    },
    // 获取统计列表
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;

      return service.getStatisticsList(params).then(res => {
        let { page = {}, list = [] } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.statisticsList = list;
        this.page = page;
      });
    },
    // 搜索
    search(data) {
      let params = { pageNum: 1, ...data };
      this.searchData = data;
      return this.getList(params);
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.user-box {
  width: 100%;

  .name {
    width: 220px;
  }

  .change {
    width: 100%;
  }

  .tool-box {
    overflow: hidden;
    text-align: left;
    padding: 10px 10px;
    border-bottom: 1px solid #eee;
  }
}
</style>
