<template>
  <div class="statis">
    <el-table :data="statisticsList" style="width: 100%" border>
      <el-table-column sortable prop="tempId" label="ID" align="center"></el-table-column>
      <el-table-column prop="channelName" label="渠道名称" align="center"></el-table-column>
      <el-table-column prop="time" label="时间" align="center"></el-table-column>
<!--      <el-table-column prop="pv" label="PV" align="center"></el-table-column>-->
<!--      <el-table-column prop="uv" label="UV" align="center"></el-table-column>-->
      <el-table-column prop="regCount" label="注册量" align="center"></el-table-column>
<!--      <el-table-column prop="uvToReg" label="UV到注册" align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ scope.row.uvToReg | toRatio }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="threeVerify" label="已认证(三项全部认证)" align="center"></el-table-column>
      <el-table-column prop="auditPassCount" label="审核通过" align="center"></el-table-column>
      <el-table-column prop="auditRefuseCount" label="审核拒绝" align="center"></el-table-column>
      <el-table-column prop="verityToAuditPass" label="认证到通过" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.verityToAuditPass | toRatio }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    statisticsList: {
      type: Array,
      default: () => []
    }
  },
  filters: {
    toRatio(r) {
      return r.toFixed(2) + '%';
    }
  }
};
</script>
<style lang="scss">
@media screen and (max-width: 480px) {
  .el-pagination{
    overflow: auto!important;
  }
}
</style>
